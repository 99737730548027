import { Link, useParams } from 'react-router-dom';
import { physicians } from '../../data';
import './helpers/SinglePhysician.css';
import ColoredBlock from './helpers/ColoredBlock';
const SinglePhysician = () => {
    const { physicianId } = useParams();
    const physician = physicians.find(
        (item) => item.name.split(' ')[0].toLowerCase() === physicianId
    );
    const { bio, image, name, practiceEmphasis, specialProcedures } = physician;
    const cacheKey = 'cached_yelp_reviews';
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(
            date
        );

        return formattedDate;
    };
    const renderPracticeEmphasisMobile = (practiceEmphasis) => {
        if (practiceEmphasis && practiceEmphasis.length > 0) {
            return (
                <div className='specialties-item'>
                    <h4>Practice Emphasis</h4>
                    <ul className='specialties-list'>
                        {practiceEmphasis.map((item, index) => {
                            return (
                                <li key={index} className='specialties-list-item'>
                                    {item}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            );
        }
    }
    const renderPracticeEmphasis = (practiceEmphasis) => {
        if (practiceEmphasis && practiceEmphasis.length > 0) {
            return (
                <div className='specialties-item'>
                    <h4>Practice Emphasis</h4>
                    <ul className='specialties-list'>
                        {practiceEmphasis.map((item, index) => {
                            return (
                                <li key={index} className='specialties-list-item'>
                                    {item}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            );
        }
    }
    const offices = [
        { city: "Chicago", number: "312-842-4600" },
        { city: "Hometown", number: "708-425-1150" },
        { city: "La Grange", number: "708-482-7767" },
        { city: "Greenwood Plaza", number: "773-735-5500" }
        // Add more offices as needed
    ];
    const getCachedReviews = () => {
        const cachedDataBeforeJson = localStorage.getItem(cacheKey);
        if (cachedDataBeforeJson) {
            const cachedDataOne = JSON.parse(cachedDataBeforeJson);
            const cachedData = JSON.parse(cachedDataOne.reviews);
            return cachedData.map((review, index) => {
                const filteredName = name
                    .split(/[,.]\s*/)
                    .filter(
                        (word) => !word.includes('.') && !word.includes(',')
                    )
                    .join(' ');

                const namesToSearch = filteredName.split(/\s+/);

                const regexPattern = namesToSearch
                    .map(
                        (name) => `\\b${name.replace('.', '\\.')}(?![\\w.,])\\b`
                    )
                    .join('|');

                const regex = new RegExp(regexPattern, 'i');

                const matchedNames = review.text.match(regex);

                if (matchedNames) {
                    if (review.user.name === "Pdub ..") {
                    } else {          
                        return (
                            <div key={index} className='single-review-container'>
                                <div className='review-top-info'>
                                    <div
                                        className='user-icon'
                                        style={{
                                            backgroundImage: `url(${review.user.image_url})`,
                                        }}>
                                        {!review.user.image_url && (
                                            <i className='fas fa-user-circle'></i>
                                        )}
                                    </div>
                                    <div className='review-name-container'>
                                        <div className='user-name'>
                                            {review.user.name}{' '}
                                            <i className='fab fa-yelp'></i>
                                        </div>
                                        <div className='review-location'>
                                            {review.location_two}
                                        </div>
                                        <div className='review-location'>
                                            {review.location_one}{' '}
                                            <span className='review-date'>
                                                Reviewed on{' '}
                                                {formatDate(review.time_created)}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className='review-info'>
                                    <i
                                        className='fa fa-quote-left'
                                        aria-hidden='true'></i>
                                    <i
                                        className='fa fa-quote-right'
                                        aria-hidden='true'></i>
                                    <p className='review-paragraph'>
                                        {review.text}
                                    </p>
                                </div>
                            </div>
                        );
                    }
                }
            });
        }
        return null;
    };
    return (
        <div className='page-container'>
            <div id="background-physician-css" className='page-grid'>
                <div className='physician-left'>
                    <div className='physician-image'>
                        <img src={image} alt={name} />
                    <a
                        style={{ alignSelf: 'center', marginTop: '5px', fontSize: '1.4rem', marginBottom: '5px', border: 'solid 1px lightblue', display: 'flex', justifyContent: 'center', width: '75%' }}
                        href='https://www.zocdoc.com/practice/midland-orthopedic-associates-17465'
                        className='btn header-button-yellow'>
                        Book Online
                    </a>
                    </div>
                    <div className="contact-info-phones">
                        <a href="tel:3128424600">
                            <ColoredBlock city="Chicago" number="312-842-4600" index={0} />
                        </a>
                       
                        <a href="tel:7084251150">
                            <ColoredBlock city="Hometown" number="708-425-1150" index={1} />
                        </a>

                        <a href="tel:7084827767">
                            <ColoredBlock city="La Grange" number="708-482-7767" index={1} />
                        </a>

                        <a href="tel:7737355500">
                            <ColoredBlock city="Greenwood Plaza" number="773-735-5500" index={0} />
                        </a>                        



                      
                    </div>


                    <div className='specialties'>
                        {renderPracticeEmphasis(practiceEmphasis)}
                        {specialProcedures.length > 0 && (
                            <div className='specialties-item'>
                                <h4>Special Procedures</h4>
                                <ul className='specialties-list'>
                                    {specialProcedures.map((item, index) => {
                                        if (item === 'Iovera treatment') {
                                            console.log('Iovera');
                                            return (
                                                <li key={index} className='specialties-list-item'>
                                                    <a href="https://www.ioverapro.com/patient/what-is-iovera" className='special-link'>{item}</a>
                                                </li>
                                            );
                                        } else {
                                            return (
                                                <li key={index} className='specialties-list-item'>
                                                    {item}
                                                </li>
                                            );
                                        }
                                    })}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
                <div className='physician-right'>
                    <h5 className='physician-name'>{name}</h5>
                    {bio.map((item, index) => {
                        return (
                            <div key={index}>
                                {index > 0 && <div className='pop-out-content'><p className='page-description'>{item}</p></div>}
                                {index === 0 && <p className='page-description'>{item}</p>}
                            </div>
                            // <p key={index} className='page-description'>
                            //     {item}
                            // </p>
                        );
                    })}
                    <div className='specialties-mobile'>
                        {renderPracticeEmphasisMobile(practiceEmphasis)}
                        <div className='specialties-item'>
                            <h4>Special Procedures</h4>
                            <ul className='specialties-list'>
                                {specialProcedures.map((item, index) => {
                                    if (item === 'Iovera treatment') {
                                        return (
                                            <li key={index} className='specialties-list-item'>
                                                <a href="https://www.ioverapro.com/patient/what-is-iovera" className='special-link'>{item}</a>
                                            </li>
                                        );
                                    } else {
                                        return (
                                            <li key={index} className='specialties-list-item'>
                                                {item}
                                            </li>
                                        );
                                    }
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {getCachedReviews()}
        </div>
    );
};

export default SinglePhysician;
